@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  font-size: xx-large !important;
}

h2 {
  font-size: x-large !important;
}

h3 {
  font-size: larger !important;
}

